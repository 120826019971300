body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dashboard-container {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.content {
  flex-basis: 68%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 3% 2%;
}

.funds {
  width: 100%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.funds p {
  font-size: 0.8rem;
  color: rgb(179, 179, 179);
  margin-right: 8px;
}

.btn {
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 2px;
  color: #fff;
  margin-right: 2px;
  margin-left: 2px;
}

.btn-green {
  background: #4caf50;
}

.btn-blue {
  background: #4184f3;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 5%;
}

.col {
  flex-basis: 48%;
  text-align: left;
}

.col span {
  display: flex;
  align-items: center;
  margin-bottom: 2%;
}

.section-icon {
  transform: scale(0.8);
  color: rgb(66, 56, 56);
  padding: 0;
  margin: 0;
  margin-right: 10px;
}

.col span p {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 300;
}

.table {
  border: 1px solid #ddd;
  padding: 5% 8%;
}

.table .data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table .data > * {
  margin-bottom: 5%;
}

.table .data p:first-child {
  font-size: 1rem;
  color: rgb(151, 151, 151);
}

.imp {
  font-size: 1.5rem;
  color: #313131;
}

.colored {
  color: #4184f3;
}

.table hr {
  border: none;
  background: #d1d1d1;
  height: 0.5px;
  margin-bottom: 5%;
}

.commodity {
  padding: 2% 8%;
  text-align: center;
}

.commodity p {
  margin-bottom: 10%;
  color: #c9c9c9;
}

/* sudo selectors */
.btn-blue:hover {
  background: #74a7fa;
}

.btn-green:hover {
  background: #7bdd7f;
}

.title {
  font-size: 1.3rem;
  font-weight: 300;
  color: rgb(71, 71, 71);
  margin-bottom: 2%;
}

.order-table table {
  width: 100%;
  border-collapse: collapse;
}

.order-table tr {
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}

.order-table th {
  text-align: right;
  padding: 15px 10px;
  color: rgb(145, 145, 145);
  font-weight: 300;
  font-size: 0.8rem;
}

.order-table tr th:first-child,
.order-table tr td:first-child {
  text-align: left;
}

.order-table tr th:nth-child(1),
.order-table tr th:nth-child(4) {
  border-right: 1px solid #f1f1f1;
}

.order-table tr td:nth-child(1),
.order-table tr td:nth-child(4) {
  border-right: 1px solid #f1f1f1;
}

.order-table tr td:last-child {
  font-size: 0.6rem;
}

.order-table td {
  padding: 10px 10px;
  text-align: right;
  font-weight: 400;
  font-size: 0.9rem;
  color: rgb(73, 73, 73);
}

.align-left {
  text-align: left;
}

.order-table td.profit {
  color: rgb(72, 194, 55);
}

.order-table td.loss {
  color: rgb(250, 118, 78);
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5%;
}

.col {
  flex-basis: 33%;
}

.col h5 {
  font-size: 1.8rem;
  color: rgb(71, 71, 71);
  font-weight: 300;
}

.col h5 span {
  font-weight: 300;
  font-size: 0.9rem;
}

.col p {
  font-size: 0.8rem;
  color: rgb(153, 153, 153);
  font-weight: 300;
  margin-top: 2%;
}

.row .col:last-child h5 {
  color: rgb(72, 194, 55);
}

/* sudo selector */
.item:hover {
  cursor: pointer;
}

.menu-container {
  flex-basis: 68%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 30px;
  height: 20px;
}

.menus {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.menus ul {
  list-style-type: none;
}

.menus li {
  display: inline-block;
  margin-right: 30px;
  text-decoration: none;
}

.menu {
  font-size: 0.8rem;
  font-weight: 400;
  color: rgb(70, 70, 70);
}

.menu.selected {
  color: rgb(245, 104, 52);
}

hr {
  border-left: 0.8px solid rgb(243, 242, 242);
  height: 30px;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 20px;
}

.profile .avatar {
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  font-size: 0.7rem;
  font-weight: 400;
  color: rgb(221, 139, 221);
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  text-align: center;
  display: flex;
  background: rgb(252, 229, 252);
  margin-right: 8px;
}

.profile .username {
  font-size: 0.8rem;
  font-weight: 300;
}

/* sudo selectors */

.menu:hover {
  color: rgb(245, 104, 52);
}

.profile:hover {
  cursor: pointer;
}

.profile:hover .username {
  cursor: pointer;
  color: rgb(223, 91, 43);
}

.orders {
  width: 100%;
  height: 90vh;
}

.no-orders {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-orders .icon {
  transform: scale(4);
  color: rgb(173, 173, 173);
  display: block;
}

.no-orders p {
  margin-top: 6%;
  color: rgb(173, 173, 173);
  font-size: 1rem;
  font-weight: 300;
}

.btn {
  text-decoration: none;
  background: rgb(77, 77, 248);
  padding: 10px 20px;
  border-radius: 2px;
  color: #fff;
  margin-top: 2%;
}

.btn:hover {
  background: rgb(110, 110, 253);
}

.title {
  font-size: 1.3rem;
  font-weight: 300;
  color: rgb(71, 71, 71);
  margin-bottom: 2%;
}

.order-table table {
  width: 100%;
  border-collapse: collapse;
}

.order-table tr {
  border-top: 1px solid rgb(211, 211, 211);
  border-bottom: 1px solid rgb(211, 211, 211);
}

.order-table th {
  text-align: right;
  padding: 15px 10px;
  color: rgb(145, 145, 145);
  font-weight: 300;
  font-size: 0.8rem;
}

.order-table tr th:first-child,
.order-table tr td:first-child {
  text-align: left;
}

.order-table tr td:first-child p {
    background: rgb(255, 197, 197);
    color: rgb(233, 120, 120);
    width: 50%;
    text-align: center;
    border-radius: 2px;
}

.order-table tr td:last-child {
  font-size: 0.6rem;
}

.order-table td {
  padding: 10px 10px;
  text-align: right;
  font-weight: 400;
  font-size: 0.9rem;
  color: rgb(73, 73, 73);
}

.align-left {
  text-align: left;
}

.order-table td.profit {
  color: rgb(72, 194, 55);
}

.order-table td.loss {
  color: rgb(250, 118, 78);
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5%;
}

.col {
  flex-basis: 33%;
}

.col h5 {
  font-size: 1.8rem;
  color: rgb(71, 71, 71);
  font-weight: 300;
}

.col h5 span {
  font-weight: 300;
  font-size: 0.9rem;
}

.col p {
  font-size: 0.8rem;
  color: rgb(153, 153, 153);
  font-weight: 300;
  margin-top: 2%;
}

.row .col:last-child h5 {
  color: rgb(72, 194, 55);
}

/* sudo selector */
.item:hover {
  cursor: pointer;
}

.username h6 {
  font-size: 1.5rem;
  font-weight: 400;
  color: rgb(83, 83, 83);
  margin-bottom: 20px;
}

.divider {
  border: none;
  background: #d1d1d1;
  height: 1px;
  margin: 20px 0;
  margin-bottom: 5%;
}

.section {
  padding-bottom: 2%;
}

.section span {
  display: flex;
  align-items: center;
  margin-bottom: 2%;
}

.section span p {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 300;
}

.section-icon {
  transform: scale(0.8);
  color: rgb(112, 112, 112);
  padding: 0;
  margin: 0;
  margin-right: 10px;
}

.data {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.data .first h3 {
  font-size: 2.5rem;
  font-weight: 300;
  color: rgb(71, 71, 71);
}

.data .first p {
  font-size: 0.8rem;
  color: rgb(136, 136, 136);
}

.data .first small {
  font-size: 0.8rem;
  color: rgb(72, 194, 55);
}

.data .first .profit {
  color: rgb(72, 194, 55);
}

.data hr {
  border-left: 0.6px solid rgb(243, 242, 242);
  height: 70px;
}

.data .second p {
  font-size: 0.8rem;
  color: rgb(136, 136, 136);
  margin-bottom: 10px;
  white-space: nowrap;
}

.data .second p span {
  display: inline;
  margin-left: 5%;
  font-size: 0.9rem;
  color: rgb(100, 100, 100);
}

.section hr {
  margin-top: 6%;
}

.topbar-container {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 4px 2px rgb(236, 235, 235);
  box-sizing: border-box;
  z-index: 9;
}

.indices-container {
  flex-basis: 32%;
  height: 100%;
  padding: 10px 20px;
  border-right: 1px solid rgb(224, 224, 224);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nifty {
  flex-basis: 40%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: -2;
}

.sensex {
  flex-basis: 40%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.nifty > *,
.sensex > * {
  margin-right: 8px;
}

.index {
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
  color: rgb(97, 97, 97);
  white-space: nowrap;
}

.index-points {
  font-size: 0.8rem;
  font-weight: 500;
  color: rgb(223, 73, 73);
}

.percent {
  font-size: 0.8rem;
  font-weight: 400;
  color: rgb(146, 146, 146);
}

/* sudo selectors */
.index:hover {
  cursor: pointer;
}

.watchlist-container {
  flex-basis: 32%;
  height: 100%;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px 1px rgb(236, 235, 235);
  overflow-y: auto;
  position: relative;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}

.search {
  width: 100%;
  height: 6%;
  border: none;
  padding: 15px 20px;
  font-size: 0.9rem;
  font-weight: 400;
  color: rgb(241, 241, 241);
  border-bottom: 0.8px solid rgb(235, 234, 234);
}

.counts {
  position: absolute;
  right: 20px;
  font-size: 0.9rem;
  font-weight: 400;
  color: rgb(177, 177, 177);
}

.list {
  list-style-type: none;
  padding-bottom: 12%;
}

.list li {
  border-bottom: 0.8px solid rgb(235, 234, 234);
  padding: 12px 10px 12px 14px;
  position: relative;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
  font-size: 0.8rem;
  position: relative;
}

.item-info {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.item-info > * {
  margin-right: 8px;
}

.item-info span:nth-child(1) {
  color: rgb(141, 141, 141);
  flex: 1;
}

.item-info span:nth-child(2) {
  color: rgb(223, 73, 73);
  margin-right: 12px;
  flex: 2;
}

.item-info span:nth-child(3) {
  color: rgb(223, 73, 73);
  flex: 1;
}

.actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  display: none;
}

.actions span button {
  width: 40px;
  height: 30px;
  border-radius: 4px;
  text-align: center;
  margin-right: 8px;
  cursor: pointer;
}

.buy {
  background-color: #4184f3;
  color: #fff;
  font-weight: 400;
  font-size: 0.8rem;
  border: 0.7px solid #4184f3;
}

.sell {
  background-color: #ff5722;
  color: #fff;
  font-weight: 400;
  font-size: 0.8rem;
  border: 0.7px solid #ff5722;
}

.action {
  background-color: #fff;
  border: 0.7px solid #9b9b9b;
}

.icon {
  transform: scale(0.7);
  color: rgb(65, 65, 65);
}

.watchlist-number {
  height: 6%;
  width: 32%;
  box-sizing: border-box;
  position: fixed;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  bottom: 1px;
  padding: 20px 20px 20px 0;
  left: 0;
  border: 1px solid rgb(231, 231, 231);
  z-index: 5;
  background: #fff;
}

.watchlist-number ul {
  list-style-type: none;
}

.watchlist-number ul li {
  display: inline-block;
  padding: 10px 20px;
  border-right: 1px solid rgb(231, 231, 231);
}

.watchlist-number ul li:nth-child(1) {
  color: rgb(223, 73, 73);
}

.down {
  color: rgb(223, 73, 73);
}

.up {
  color: rgb(103, 201, 136);
}

.settings {
  transform: scale(0.8);
  color: rgb(105, 105, 105);
}

/* sudo selectors */
.search:focus {
  outline: none;
}

.list li:hover {
  cursor: move;
  background-color: #f3f3f3;
}

.list li:hover > .actions {
  display: flex;
  cursor: move;
}

/* TODO */
.list li::before {
  content: "";
  background: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.watchlist-number ul li:hover {
  color: rgb(245, 104, 52);
  cursor: pointer;
  background: rgb(243, 243, 243);
}

.settings:hover {
  cursor: pointer;
}

.action:hover {
  background-color: rgb(212, 212, 212);
}



